



















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import _ from "lodash";


@Component({ components: {} })
export default class PDFUserDataHelper extends Vue {
  @Prop({}) public disclosure: any;
  @Prop({ default: "pWSX7x4r" }) public loanTxnId: string;
  userData: any = new Array();
  filter: string = "";

  async getUserDataJSON() {
    let response = await Axios.post(
      BASE_API_URL + "loan/getLoanUserData",
      { loanTxnId: this.loanTxnId }
    );
    let userData: any;
    userData = _.head(_.get(response, 'data.userData', []));

    delete userData.documents;
    delete userData.docMap;

    this.userData = this.flatten(userData);

    this.$emit('gotUserData', this.userData);
  }

  // @Watch("disclosure", { deep: true })
  // onDisclosureSave() {
  //   if (this.disclosure && this.disclosure.validate) {
  //     this.disclosure.fields.forEach((field) => {
  //       if (
  //         field.source === "db" &&
  //         ((field.type !== "checkbox" && !this.userData.find((line) => line[0] === field.fieldMapName) 
  //         ||
  //           (field.type === "checkbox" &&
  //             !this.userData.find(
  //               (line) =>
  //                 line[0] ===
  //                 field.fieldMapName.substring(
  //                   0,
  //                   field.fieldMapName.lastIndexOf("=")
  //                 )
  //             ))))
  //       ) {
  //         console.log(`Field not mapped correctly: ${field.fieldMapName}`);
  //         this.$snotify.error(
  //           `Field not mapped correctly: ${field.fieldMapName}`,
  //           {
  //             timeout: 10000,
  //           }
  //         );
  //       }
  //     });
  //   }
  // }

  get filteredUserData() {
    if (Array.isArray(this.userData)) {
      return this.userData.filter((data) =>
        this.filter
          .split(/\s+/g)
          .every((filter) =>
            data[0].toLowerCase().includes(filter.toLowerCase())
          )
      );
    }
  }

  selectKey(key) {
    this.$emit("onKeySelected", key);
  }

  async mounted() {
    await this.getUserDataJSON();
    const arr = [];
    const pattern = /\.([0-9]*?)\./g;
    for (var data in this.userData) {
      let replacedString = data;
      if (data.match(pattern)) {
        const exec = pattern.exec(data);
        replacedString = data.replace(pattern, `[${exec['1']}].`);
      }
      arr.push([replacedString, this.userData[data]]);
    }
    this.userData = arr.sort();
  }

  flatten(object) {
    const iter = (o, p) => {
      if (o && typeof o === "object") {
        Object.keys(o).forEach(function (k) {
          iter(o[k], p.concat(k));
        });
        return;
      }
      path[p.join(".")] = o;
    };
    var path = {};
    iter(object, []);
    return path;
  }
}
