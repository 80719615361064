




















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PDFJS from "@/pdfjs";
import PDFConstructorPage from "@/components/pdf/PDFConstructorPage.vue";
import Axios from "axios";
import PDFViewer from "./PDFViewer.vue";
import _ from 'lodash';
import { BASE_API_URL } from "@/config";

@Component({ components: { PDFConstructorPage, PDFViewer } })
export default class PDFConstructor extends Vue {

  @Prop({ required: true }) public disclosure: any;
  @Prop() public initialFields: any[];
  @Prop() userData;

  pdf: string = "";
  documentFields: any = [];
  documentFieldsWithData: any = [];
  pages: any = [];
  error: boolean = false;
  showPDFViewer: boolean = false;

  $refs!: {
    wrapper: any,
    page: any,
  };

  get pdfHREF() {
    return `data:application/pdf;base64,${this.pdf}`;
  }

  addKeyToSelectedField(key) {
    this.$refs.page.forEach(page => {
      const selectedField = _.get(page, 'documentFields', []).find(field => field.isSelected);

      if (selectedField) {
        selectedField.fieldMapName = key;
      }
    })
  }

  deselectAllFields() {

    this.$refs.page.forEach(page => {
      page['deselectAllFields'](null, false);
    });
  }

  showPayload: boolean = false;
  loading: boolean = false;

  @Watch('userData')
  receiveUserData(value, oldValue) {
    if (!value) { return; }

    this.userData = value;
    this.insertFieldsData();
  }

  insertFieldsData() {

    this.documentFieldsWithData = this.documentFields.map(field => {
      field.data = _.get(this.userData, field.fieldMapName, null);
      field.needToBeSignedBy = [];

      return field;
    });

    setTimeout(function() {
      this.showPDFViewer = true;
    }.bind(this), 300);
  }

  async mounted() {
    this.showPDFViewer = false;
    this.documentFields = this.disclosure['fields'] || [];
    this.receiveUserData(this.userData, null);
    this.processPDF();
  }

  async save () {
    if (this.loading) { return; }

    await Axios.post(
      BASE_API_URL + "disclosure/saveWesignFormFields",
      { formId: parseInt(this.disclosure['formId']), fields: this.documentFields, mapped: this.disclosure['mapped'] });

    this.disclosure['fields'] = this.documentFields;

    this.$snotify.success("Forms saved successfully", {
      timeout: 1000
    });
    
    this.$emit('update:disclosures', this.disclosure);
    this.$emit('validate:disclosure', this.disclosure, true);
  }

  updatePDFViewer() {

    if (!this.userData) { return; }

    this.showPDFViewer = false;
    this.receiveUserData(this.userData, null);
  }

  getUpdatedFields({ fields, pageNumber }) {
    const nonPageFields = this.documentFields.filter(field => field.page !== pageNumber)

    let fieldIdCounter = 1;

    const fieldsWithCorrectIds = [
      ...nonPageFields,
      ...fields
    ].map(field => ({
      ...field,
      fieldId: fieldIdCounter++
    }));

    this.documentFields = fieldsWithCorrectIds;
  }

  async getPages(pdf, numberOfPages) {
    const promises = Array.from(Array(numberOfPages), (_, i) => i + 1)
      .map(async (number) => ({
        number,
        page: await pdf.getPage(number),
        fields: this.documentFields.filter(field => field.page === number)
      }));
    
    return Promise.all(promises);
  }

  async downloadPDF(file) {
    let response = await Axios.post(
      BASE_API_URL + "common/fetchDocument",
      { path: `/WesignForms/Mapped/${file}` }
    );
    this.pdf = response.data;
    return response.data;
  }

  public async processPDF() {
    try {
      const pdfDocument = await this.downloadPDF(this.disclosure['filename']);
      const pdf = await PDFJS.getDocument({ data: atob(pdfDocument) }).promise;
      this.pages = await this.getPages(pdf, pdf.numPages);
    } catch (error) {
      this.error = true;
    }
  }
}
