




































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class PDFPage extends Vue {

  @Prop({ required: true }) page: any;
  @Prop({ required: true }) pageNumber: number;
  @Prop() initialFields: any;
  @Prop() maxId: number;

  documentFields: any = [];
  selectedField: any = null;

  fieldTypes: string[] = [
    'textfield',
    'textarea',
    'checkbox',
    'select',
    'wesign'
  ];

  userTypes: any[] = [
    null,
    'broker',
    'borrower',
    'coborrower'
  ];

  sourceTypes: string[] = [
    'db',
    'user'
  ];

  $refs!: {
    pdf: any,
    canvas: any,
    fieldsLayer: any
  };

  selectField(event, selectedField) {
    event.stopPropagation();

    if (!selectedField) { return; }

    const field = this.documentFields.find(f => f.fieldId === selectedField.fieldId);

    if(field){
      this.deselectAllFields(field.fieldId);
      field.isSelected = true;

      this.documentFields = [
        ...this.documentFields.filter(f => f.fieldId !== selectedField.fieldId),
        field
      ];
    }
  }

  deselectAllFields(exceptFieldId = null, emit = true) {
    const updatedDocumentsFields = this.documentFields
      .filter(field => field.fieldId !== exceptFieldId)
      .map(field => {
        field.isSelected = false;
        return field;
      });

    this.documentFields = updatedDocumentsFields;

    if (emit) { this.$emit('deselectAllFields'); }
  }

  @Watch('documentFields', { deep: true })
  onFieldsChange(fields, oldFields) {
    this.$emit('fieldsChanged', { fields, pageNumber: this.pageNumber });
  }

  async mounted() {
    this.documentFields = this.initialFields || [];
    this.deselectAllFields();

    this.drawPDF();
  }

  public addField(event) {
    if (event.target !== this.$refs.fieldsLayer) { return; }

    const fieldsLayerPosition = this.$refs.fieldsLayer.getBoundingClientRect()

    this.documentFields.push({
      fieldId: this.maxId + 1,
      type: 'textfield',
      fieldMapName: 'fieldMapName',
      source: 'db',
      page: this.pageNumber,
      position: {
        width: 100,
        height: 20,
        x: event.pageX - fieldsLayerPosition.x - window.pageXOffset,
        y: event.pageY - fieldsLayerPosition.y - window.pageYOffset
      },
      enabled: true,
      defaultValue: '',
      toBeSignedBy: "borrower"
    });
  }

  public removeField(fieldId) {
    this.documentFields = this.documentFields.filter(field => field.fieldId !== fieldId);
  }

  public selectText(event) {
    window.getSelection().selectAllChildren(event.target);
  }

  public initResize(event, field) {
    this.selectedField = field;

    window.addEventListener('mousemove', this.resize, false);
    window.addEventListener('mouseup', this.stopResize, false);
  }

  public stopResize() {
    this.selectedField = null;

    window.removeEventListener('mousemove', this.resize, false);
    window.removeEventListener('mouseup', this.stopResize, false);
  }

  public resize(event) {
    const fieldsLayerPosition = this.$refs.fieldsLayer.getBoundingClientRect()

    const width = event.pageX - fieldsLayerPosition.x - window.pageXOffset - this.selectedField.position.x;
    const height = event.pageY - fieldsLayerPosition.y - window.pageYOffset - this.selectedField.position.y;
    
    this.selectedField.position.width = width > 15 ? width : 15;
    this.selectedField.position.height = height > 15 ? height : 15;
  }
  
  public initMove(event, field) {
    this.selectedField = field;

    window.addEventListener('mousemove', this.move, false);
    window.addEventListener('mouseup', this.stopMove, false);
  }

  public stopMove() {
    this.selectedField = null;

    window.removeEventListener('mousemove', this.move, false);
    window.removeEventListener('mouseup', this.stopMove, false);
  }

  public move(event) {
    const fieldsLayerPosition = this.$refs.fieldsLayer.getBoundingClientRect()

    const width = event.pageX - fieldsLayerPosition.x - window.pageXOffset - (this.selectedField.position.width / 2);
    const height = event.pageY - fieldsLayerPosition.y - window.pageYOffset - (this.selectedField.position.height / 2);
    
    this.selectedField.position.x = width;
    this.selectedField.position.y = height;
  }

  public async drawPDF() {

    const canvas = this.$refs.canvas;
    const context = canvas.getContext('2d');

    const viewport = this.page.getViewport({ scale: 1 });

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    this.$refs.pdf.style.width = `${viewport.width}px`;
    this.$refs.pdf.style.height = `${viewport.height}px`;

    await this.page.render({
      canvasContext: context,
      viewport
    }).promise;
  }
}
