







import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ components: {} })
export default class PDFMetrics extends Vue {
  @Prop({ required: true }) public disclosures: any;

  disclosuresMetrics: Object = {};

  @Watch('disclosures', { deep: true })
  onDisclosuresChange() {
    this.disclosuresMetrics = {
      total: this.disclosures.length,
      started:
        this.disclosures.reduce((n, disclosure) => {
          return n + (disclosure["fields"].length > 0);
        }, 0) || 0,
      completed:
        this.disclosures.reduce((n, disclosure) => {
          return n + (disclosure["mapped"]);
        }, 0) || 0,
    };
  }

}
